/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Grid, ListGroup, ListGroupItem } from '@freecodecamp/react-bootstrap';
import { Link, graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import ButtonSpacer from '../../components/helpers/button-spacer';
import FullWidthRow from '../../components/helpers/full-width-row';
import Header from '../../components/landing/components/header';
import Footer from '../../components/landing/components/footer';
import LearnLayout from '../../components/layouts/learn';
import {
  MarkdownRemark,
  AllChallengeNode,
  ChallengeNode
} from '../../redux/prop-types';

import './intro.css';
import { makeExpandedBlockSelector } from './redux';
import { createSelector } from 'reselect';
import { completedChallengesSelector, userSelector } from '../../redux';
import { connect } from 'react-redux';

function renderMenuItems({
  edges = []
}: {
  edges?: Array<{ node: ChallengeNode }>;
}) {
  return edges
    .map(({ node: { challenge } }) => challenge)
    .map(({ title, fields: { slug } }) => (
      <Link key={'intro-' + slug} to={slug}>
        <ListGroupItem>{title}</ListGroupItem>
      </Link>
    ));
}

function IntroductionPage({
  completedChallenges,
  user,
  data: { markdownRemark, allChallengeNode }
}: {
  completedChallenges: any;
  user: any;
  data: {
    markdownRemark: MarkdownRemark;
    allChallengeNode: AllChallengeNode;
  };
}): React.FunctionComponentElement<typeof LearnLayout> {
  const { t } = useTranslation();
  const {
    html,
    frontmatter: { block }
  } = markdownRemark;
  const firstLesson =
    allChallengeNode && allChallengeNode.edges[0].node.challenge;
  const firstLessonPath = firstLesson
    ? firstLesson.fields.slug
    : '/strange-place';


  const [isSticky, setSticky] = useState(true);
  const ref: any = useRef(null);
  const ref_w: any = useRef(null);
  const handleScroll = () => {
    // const elem: any = document.getElementsByClassName("job_details_info")[0];
    if (ref_w.current) {
      const val = ref.current.parentElement;
      if (val.clientWidth > 400) { return; }
      setSticky(ref_w.current.getBoundingClientRect().top <= 120);
      ref.current.style.top = '120px';
      ref.current.style.left = val.getBoundingClientRect().left + 15 + 'px';
      ref.current.style.height = 'auto';
      ref.current.style.width = val.clientWidth - 30 + 'px';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.AOS.init({
      // disable: function() {
      //   var maxWidth = 769;
      //   return window.innerWidth < maxWidth;
      // }
      once: true,
      duration: 800,
    });
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const chapterClicked = function(lesson: any, locked: any) {
    // if(!locked) {
      location.href = lesson.node.challenge.fields.slug;
    // } else {
    //   console.log(lesson.node.challenge.fields.slug);
    // }
  }

  const currentChallenge: any = allChallengeNode.edges.find((lesson: any) => completedChallenges.indexOf(lesson.node.challenge.id) == -1);

  return (
    <>
      <Helmet>
        <title>Extraise</title>
        <link rel="shortcut icon" href="/assets/images/logo/favourite_icon_1.png" />
        <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/icomoon.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/themify-icons.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/fontawesome-all.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.carousel.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.theme.default.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/aos.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/animate.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/splitting.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/magnific-popup.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/_extraise.css" />
        <script defer key="jquery" src="/assets/js/jquery-3.4.1.min.js" />
        <script defer key="popper" src="/assets/js/popper.min.js" />
        <script defer key="bootstrap" src="/assets/js/bootstrap.min.js" />
        <script defer key="owl-carousel" src="/assets/js/owl.carousel.min.js" />
        <script defer key="aos" src="/assets/js/aos.js" />
        <script defer key="splitting" src="/assets/js/splitting.js" />
        <script defer key="magnific-popup" src="/assets/js/magnific-popup.min.js" />
        <script defer key="isotope" src="/assets/js/isotope.pkgd.min.js" />
        <script defer key="masonry" src="/assets/js/masonry.pkgd.min.js" />
        <script defer key="imagesloaded" src="/assets/js/imagesloaded.pkgd.min.js" />
        <script defer key="parallax" src="/assets/js/parallax.min.js" />
        <script defer key="parallax-scroll" src="/assets/js/parallax-scroll.js" />
        <script defer key="gmaps" src="/assets/js/gmaps.js" />
        <script defer key="mCustomScrollbar" src="/assets/js/mCustomScrollbar.js" />
        <script defer key="custom" src="/assets/js/custom.js" />
      </Helmet>
      <Header />
      <main>
        <section id="breadcrumb_section" className="breadcrumb_section bg_gradient_blue deco_wrap d-flex align-items-center text-white clearfix" style={{ padding: '80px 0px 30px', minHeight: 'auto' }}></section>

        <section className="details_section faq_section sec_ptb_120 bg_gray clearfix">
          <div className={`container sticky-wrapper ${isSticky ? 'sticky' : ''}`} ref={ref_w}>
            <div className="row justify-content-lg-between justify-content-md-center justify-content-sm-center">
              <div className="col-lg-4 col-md-9 col-sm-10 order-last">
                <div className="job_details_info ul_li_block sticky-inner" data-aos="fade-up" data-aos-delay={400} ref={ref}>
                  <ul className="clearfix">
                    <li>
                      <div className="item_icon">
                        <i className="ti-write" style={{ color: "#007bff", fontWeight: 900 }} />
                      </div>
                      <div className="item_contact">
                        <h4 style={{ marginTop: '7px' }}>{allChallengeNode.edges.length} Chapters</h4>
                      </div>
                    </li>
                    {/* <li>
                      <div className="item_icon">
                        <i className="ti-timer" style={{ color: "#007bff", fontWeight: 900 }} />
                      </div>
                      <div className="item_contact">
                        <h4 style={{ marginTop: '7px' }}>{Math.round(allChallengeNode.edges.length / 60)} Hours to complete</h4>
                      </div>
                    </li> */}
                  </ul>
                  { currentChallenge && <a className='btn bg_default_blue' href={currentChallenge.node.challenge.fields.slug} style={{ borderRadius: 0, color: "#fff", width: "100%", marginTop: "30px" }}>Start Learning</a> }
                </div>
              </div>
              <div className="col-lg-8 col-md-9 col-sm-10 chield_item">
                <div className="item_content">
                  <div data-aos="fade-up" data-aos-delay={300} dangerouslySetInnerHTML={{ __html: html }} style={{ whiteSpace: "break-spaces" }}>
                  </div>
                  {/* <div className="element-item london newyork" data-category="london">
                    <div data-aos="fade-up" data-aos-delay={300}>
                      <div className="job_item clearfix">
                        <div className="item_content">
                          <h3 className="item_title"><a href="#!">Cashier For Bank Desk Urgent Required</a></h3>
                          <p className="mb-0">December 27, 2020</p>
                        </div>
                        <a href="job_apply.html" className="btn btn_border">Apply Now</a>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="item_content">
                  <div id="license" className="mb-80">
                    <h3 className="title_text mb-50"></h3>
                    <div id="accordion_license" className="faq_accordion">
                      <div className="card">
                        <div className="card-header">
                          <h6 className="">
                            Chapters
                          </h6>
                        </div>
                        <div id={"collapse_lessons"} className="collapse show" data-parent="#accordion_license">
                          <div className="card-body chapters" style={{ maxHeight: "initial" }}>
                            <ul className="clearfix mb-50">
                              {allChallengeNode.edges.map((lesson: any, index: any) => {
                                const locked = (index > 0 && !user.id) || (index > 0 && !user.isDonating && completedChallenges.length >= 20 && completedChallenges.indexOf(lesson.node.challenge.id) == -1);
                                return <li key={index}>
                                  <a href='#' className={ locked ? 'locked' : '' } onClick={ () => chapterClicked(lesson, locked) }>
                                    { !locked &&
                                      (completedChallenges.findIndex((c: any) => c == lesson.node.challenge.id) != -1 ?
                                        <svg height={25} viewBox="0 0 200 200" width={25} xmlns="http://www.w3.org/2000/svg" style={{ height: '18px', marginRight: '20px', width: '24px' }}><g><title>Passed</title><circle cx={100} cy={100} fill="#007bff" r={90} strokeDasharray="null" /><rect fill="var(--primary-background)" height={15} stroke="var(--primary-background)" strokeDasharray="null" transform="rotate(-45, 120, 106.321)" width="128.85878" x="73.57059" y="91.32089" /><rect fill="var(--primary-background)" height={15} stroke="var(--primary-background)" strokeDasharray="null" transform="rotate(45, 66.75, 123.75)" width="63.66548" x="26.41726" y="108.75" /></g></svg> : <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" className="dark:text-dark-contrastText fill-current"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 6a6 6 0 110 12 6 6 0 010-12m0-2a8 8 0 100 16 8 8 0 000-16z"></path></svg>)
                                    }
                                    { locked && <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" className="dark:text-dark-contrastText fill-current"><path d="M0 0h24v24H0z" fill="none"></path><path d="M18 8h-1V6A5 5 0 007 6v2H6a2 2 0 00-2 2v10c0 1.1.9 2 2 2h12a2 2 0 002-2V10a2 2 0 00-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6a3.1 3.1 0 016.2 0v2z"></path></svg>}
                                    <span>{lesson.node.challenge.title}</span>
                                    { index == 0 && <div style={{ border: "2px solid #333",lineHeight: "25px",padding: "0 11px",fontSize: "14px" }}>Preview</div>}
                                  </a>
                                </li>
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
  return (
    <LearnLayout>
      <Helmet>
        <title>{block} | extraise.com.org</title>
      </Helmet>
      <Grid className='intro-layout-container'>
        <FullWidthRow>
          <div
            className='intro-layout'
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </FullWidthRow>
        <FullWidthRow>
          <Link
            className='btn btn-lg btn-primary btn-block'
            to={firstLessonPath}
          >
            {t('buttons.first-lesson')}
          </Link>
          <ButtonSpacer />
          <Link className='btn btn-lg btn-primary btn-block' to='/learn'>
            {t('buttons.view-curriculum')}
          </Link>
          <ButtonSpacer />
          <hr />
        </FullWidthRow>
        <FullWidthRow>
          <h2 className='intro-toc-title'>{t('learn.upcoming-lessons')}</h2>
          <ListGroup className='intro-toc'>
            {allChallengeNode ? renderMenuItems(allChallengeNode) : null}
          </ListGroup>
        </FullWidthRow>
      </Grid>
    </LearnLayout>
  );
}

const mapStateToProps = (state: any, ownProps: any) => {
  const expandedSelector = makeExpandedBlockSelector(ownProps.blockDashedName);
  return createSelector(
    expandedSelector,
    completedChallengesSelector,
    userSelector,
    (isExpanded: any, completedChallenges: any, user: any) => ({
      isExpanded,
      completedChallenges: completedChallenges.map(({ id }: any) => id),
      user
    })
  )(state);
};

IntroductionPage.displayName = 'IntroductionPage';

export default connect(mapStateToProps)(IntroductionPage);

export const query = graphql`
  query IntroPageBySlug($slug: String!, $block: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        block
      }
      html
    }
    allChallengeNode(
      filter: { challenge: { block: { eq: $block } } }
      sort: {
        fields: [
          challenge___superOrder
          challenge___order
          challenge___challengeOrder
        ]
      }
    ) {
      edges {
        node {
          challenge {
            id
            fields {
              slug
            }
            title
          }
        }
      }
    }
  }
`;
